import React, { useRef, useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Button,
} from "@mui/material";
import { createScan, validateScan } from "../../sliceRoutes/scanRoutes";
import {
  faArrowsRotate,
  faBarcode,
  faCircleCheck,
  faCircleXmark,
  faQrcode,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./shippingPage.css";
import { useAppDispatch } from "../../app-redux/hooks";
import { ScanObject, ValidationMessage } from "../../common/interfaces";
import { loginNewUser } from "../componentHelperFunctions/userLogin"
import { autoLogout } from "../componentHelperFunctions/autoLogout";

export default function ShippingPage() {
  autoLogout();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [rfidScan, setRFID] = useState<string>("");
  const [barCode, setBarcode] = useState<string>("");
  const [orderBarcode, setOrderBarcode] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<ValidationMessage>({ type: "", message: "" });
  const rfidInputRef = useRef<HTMLInputElement>(null);
  const barcodeInputRef = useRef<HTMLInputElement>(null);
  const orderBarcodeInputRef = useRef<HTMLInputElement>(null);
  const lastFocusedInputRef = useRef<HTMLInputElement | null>(null);

  const updateLastFocusedInput = (ref: React.RefObject<HTMLInputElement>) => {
    lastFocusedInputRef.current = ref.current; // Access the current property
  };

  const handleRFIDScan = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValidationMessage({ type: "", message: "" });
    setRFID(e.target.value);
    localStorage.setItem("lastActivity", new Date().toISOString());
    serialNumberDebounceFuncRef.current(e.target.value);
  }

  const hex_to_ascii = (str1: string) => {
    let hex = str1.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }

  // useRef to store the debounce function
  const serialNumberDebounceFuncRef = useRef(
    debounce((value) => {
      if (value.length === 24) {
        const serial_number: string = hex_to_ascii(value);
        setRFID(serial_number);
        dispatch(validateScan(serial_number))
          .then((response: any) => {
            // console.log(response);
            if (response.type === 'validateScan/rejected') {
              setValidationMessage({ type: "error", message: response.payload.detail });
              setRFID("");
            } else {
              document.getElementById("barcode_scan")?.focus();
            }
          })
          .catch((error: any) => {
            console.error("error in validating scan", error);
          })
      } else if (value.startsWith("E")) {
        loginNewUser(dispatch, value)
      } else {
        setValidationMessage({ type: "error", message: "Not a valid serial number" });
      }
    }, 200)
  );

  const handleBarcodeScan = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(e.target.value);
    if (e.target.value.length === 7) {
      setBarcode(e.target.value);
      validateSerialNumbers(e.target.value);
    }
  }

  const handleOrderBarcodeScan = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderBarcode(e.target.value);
  }

  const handleRefresh = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValidationMessage({ type: "", message: "" });
    setRFID("");
    setBarcode("");
    setOrderBarcode("");
    document.getElementById("rfid_scan")?.focus();
  }

  useEffect(() => {
    const employeeJSON = localStorage.getItem("employee");
    if (employeeJSON !== null) {
      try {
        const employee = JSON.parse(employeeJSON);
        setUser(employee.name);
        const user_id = employee.employee_number.substring(3);
        setUserId(user_id);
        document.getElementById('rfid_scan')?.focus()
        localStorage.setItem("lastActivity", new Date().toISOString());
      } catch (e) {
        console.error("Failed to parse employee data:", e);
      }
    } else {
      console.log("No employee data in localStorage.");
    }

  }, []);

  useEffect(() => {
    if (rfidInputRef.current !== null) {
      rfidInputRef.current.focus();
      setValidationMessage({ type: "", message: "" });
      setRFID("");
      setBarcode("");
      setOrderBarcode("");
    }
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      rfidInputRef.current &&
      barcodeInputRef.current &&
      orderBarcodeInputRef.current &&
      lastFocusedInputRef.current
    ) {
      lastFocusedInputRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [rfidScan]);

  const handleRFIDFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    updateLastFocusedInput(rfidInputRef);
  };
  
  const handleBarcodeFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    updateLastFocusedInput(barcodeInputRef);
  };
  
  const handleOrderBarcodeFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    updateLastFocusedInput(orderBarcodeInputRef);
  };


  // Render validation response
  const renderValidationResponse = () => {
    switch (validationMessage.type) {
      case "error":
        return (
          <Box className="scan_message_box">
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{ fontSize: "35px", color: "#C62D22" }}
            />
            <Typography
              style={{ fontSize: "25px", fontWeight: "800", color: "#C62D22", marginLeft: "10px" }}
            >
              {validationMessage.message}  {/* Error message */}
            </Typography>
          </Box>
        );

      case "success":
        return (
          <Box className="scan_message_box">
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ fontSize: "35px", color: "#008000" }}
            />
            <Typography
              style={{ fontSize: "25px", fontWeight: "800", color: "#008000", marginLeft: "10px" }}
            >
              {validationMessage.message}  {/* Success message */}
            </Typography>
          </Box>
        );
      default:
        return (
          <Box className="scan_message_box">
            <FontAwesomeIcon icon={faSearch} style={{ fontSize: "35px" }} />
            <Typography style={{ fontSize: "25px", fontWeight: "800", marginLeft: "10px" }}>
              Scan Product To Begin Validation
            </Typography>
          </Box>
        );
    }
  };

  const validateSerialNumbers = (barCode: string) => {
    const serial_number: string = rfidScan.substring(0, 7);
    if (barCode.trim() !== serial_number.trim()) {
      console.log("validation failed", serial_number, barCode);
      setValidationMessage({ type: "error", message: 'Validation Failed' });
    } else {
      document.getElementById("order_scan")?.focus();
      setValidationMessage({ type: "success", message: 'Validation Successful' });
    }
  };

  function sanitizeScanData(scanData: string) {
      // eslint-disable-next-line no-control-regex
    return scanData.replace(/\u0000/g, '');
  }

  const handleCreateScan = () => {
    const date: any = new Date().toISOString();
    const sanitizedRfidScan = sanitizeScanData(rfidScan);
    const sanitizedBarcodeScan = sanitizeScanData(barCode);
    const scanObject: ScanObject = {
      user_id: userId,
      rfid_scan: sanitizedRfidScan,
      barcode_scan: sanitizedBarcodeScan,
      pass_fail: 1,
      order_number: orderBarcode? orderBarcode: null,
      date: date
    }
    if(sanitizedRfidScan.length > 0 && sanitizedBarcodeScan.length > 0) {
      dispatch(createScan(scanObject))
        .then((response: any) => {
          if (response.type === "createScan/fulfilled") {
            setValidationMessage({ type: "success", message: "Scan created" });
            setRFID("");
            setBarcode("");
            setOrderBarcode("");
            document.getElementById("rfid_scan")?.focus();
          }
        })
        .catch((error: any) => {
          console.error("error in validating scan", error);
        })
    } else {
      setValidationMessage({ type: "error", message: 'Scan an item' });
    }
  }

  return (
    <Grid style={{ height: "100vh", alignContent: "center" }}>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Box>
          <Typography className="shipping_validation_text">
            Shipping Validation Scan
          </Typography>
          <Typography className="shipping_validation_text">
            User: {user}
          </Typography>
          <Typography className="shipping_validation_text">
            User Id: {userId}
          </Typography>
        </Box>
      </Box>
      {renderValidationResponse()}
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Box
          style={{
            border: "solid",
            padding: "35px 100px",
            borderColor: "#203860",
          }}
        >
          <Typography
            style={{ fontSize: "40px", fontWeight: "800", color: "#203860" }}
          >
            Scan Data
          </Typography>
          <form >
            <Typography>RFID Scan - Law Tag Number</Typography>
            <TextField
              id="rfid_scan"
              className="scan_input"
              value={rfidScan}
              onChange={handleRFIDScan}
              onFocus={handleRFIDFocus}
              label="Serial Number"
              inputRef={rfidInputRef}
              variant="filled"
              inputProps={{ tabIndex: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faQrcode} size="lg" className="icon" />
                  </InputAdornment>
                ),
              }}
            />
            <Typography>Barcode Scan - Law Tag Number</Typography>
            <TextField
              id="barcode_scan"
              className="scan_input"
              value={barCode}
              onChange={handleBarcodeScan}
              onFocus={handleBarcodeFocus}
              inputRef={barcodeInputRef}
              label="Barcode"
              variant="filled"
              inputProps={{ tabIndex: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faBarcode} size="lg" />
                  </InputAdornment>
                ),
              }}
            />
            <Typography>Barcode Scan - Order Number</Typography>
            <TextField
              id="order_scan"
              className="scan_input"
              value={orderBarcode}
              onChange={handleOrderBarcodeScan}
              onFocus={handleOrderBarcodeFocus}
              label="Barcode"
              variant="filled"
              inputRef={orderBarcodeInputRef}
              inputProps={{ tabIndex: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      style={{ marginTop: "2px" }}
                      icon={faBarcode}
                      size="lg"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Box style={{ display: "flex", justifyContent: "center", marginTop: "8%" }}>
              <Button
                onClick={handleCreateScan}
                style={{
                  backgroundColor: "#203860",
                  height: "60px",
                  width: "215px",
                  borderRadius: "8px",
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <Box style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Button
          style={{
            backgroundColor: "#203860",
            height: "60px",
            width: "215px",
            borderRadius: "8px",
            marginTop: "2%",
          }}
          onClick={handleRefresh}
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faArrowsRotate} />}
        >
          Refresh Screen
        </Button>
      </Box>
    </Grid>
  );
};