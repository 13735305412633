import { employeeNumberLogin } from "../../sliceRoutes/userRoutes";

function logout_helper() {
  let logoutItems = [
    "employee",
  ];
  logoutItems.forEach((obj) => {
    localStorage.removeItem(obj);
  });
  window.location.href = "/";
}

//This function is use to login a new employee to a station if another user is already logged into it.
export async function loginNewUser(dispatch: any, employeeNumber: string) {

  const employeeDataString: any = window.localStorage.getItem("employee");
  const user: { [key: string]: any } | null = JSON.parse(employeeDataString);
  const e2c_employee_id: string = employeeNumber.substring(0, 1) + "2C" + employeeNumber.substring(1);

  if (user !== null) {
    if (e2c_employee_id.toUpperCase().includes(user.employee_number)) {
      logout_helper();
    } else if (e2c_employee_id.toUpperCase() !== user.employee_number) {
      await dispatch(employeeNumberLogin(employeeNumber)).then((response: any) => {
        console.log(response);
        if (response.type === "user/employeeNumberLogin/fulfilled") {
          window.localStorage.setItem("employee", JSON.stringify(response.payload));
          window.location.href = "/shipping";
        } else {
          logout_helper();
        }
      });
    };
  };

};
