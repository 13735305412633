import { createAsyncThunk } from "@reduxjs/toolkit";
import {api} from "../utils/api";
// import { getToken } from "../utils/auth";
import { StandardError, ScanObject } from "../common/interfaces";

export const getScans = createAsyncThunk(
    'getScans',
    async (_, { rejectWithValue }) => {
        try {
            // const token = await getToken();
            // if (!token) {
            //     throw new Error("Authentication token not found.");
            // }
            const response = await api.get('/scan/scans/');
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                detail: error.detail || 'Unknown error',
                statusCode: error.statusCode
            } as StandardError);
        }
    }
);

export const createScan = createAsyncThunk(
  'createScan',
  async (scanObject: ScanObject, { rejectWithValue }) => {
      try {
          // const token = await getToken();
          // if (!token) {
          //     throw new Error("Authentication token not found.");
          // }
          // const response = await api.post('/scan/create_scan', scanObject, {
          //     headers: { Authorization: `Bearer ${token}` }
          // });
          const response = await api.post('/scan/create_scan', scanObject);
          return response.data;
      } catch (error: any) {
          return rejectWithValue(error.response.data);
      }
  }
);


export const validateScan = createAsyncThunk(
  'validateScan',
  async (serialNumber: string, { rejectWithValue }) => {
      try {
          // const token = await getToken();
          // if (!token) {
          //     throw new Error("Authentication token not found.");
          // }
          // const response = await api.post(`/scan/validate_scan/${serialNumber}`, {}, {
          //     headers: { Authorization: `Bearer ${token}` }
          // });
          const response = await api.post(`/scan/validate_scan/${serialNumber}`);
          return response.data;
      } catch (error: any) {
          return rejectWithValue(error.response.data);
      }
  }
);
