import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app-redux/store";
import { BrowserRouter } from "react-router-dom";
// MSAL imports
// import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, AccountInfo } from "@azure/msal-browser";
// import { MsalProvider, useMsal } from "@azure/msal-react";
// import msalConfig from "./config/authConfig";

// interface IdTokenClaims {
//     roles?: string[];
// }

// interface CustomAuthenticationResult extends AuthenticationResult {
//     idTokenClaims: IdTokenClaims;
// }


// const TokenRenewalInterval = 600000; // 10 minutes

// const AppWrapper: React.FC = () => {
//     const { instance } = useMsal();

//     useEffect(() => {
//         const obtainAccessToken = async () => {
//             const tokenRequest = {
//                 scopes: ["api://9590ef43-f2b0-462c-b39c-9cda7768897c/scan.all"],
//             };
//             try {
//                 const response = await instance.acquireTokenSilent(tokenRequest) as AuthenticationResult;
//                 if (response) {
//                     localStorage.setItem("azureToken", response.accessToken);
//                     localStorage.setItem("username", response.account?.username ?? "");
//                 }
//             } catch (error) {
//                 console.error("Error obtaining access token:", error);
//                 // Log out the user or redirect to a logout page
//                 await instance.logout();
//             }
//         };

//         const tokenRenewalTimer = setInterval(async () => {
//             try {
//                 await obtainAccessToken();
//                 console.log("Token renewed successfully.");
//             } catch (error) {
//                 console.error("Error during token renewal:", error);
//             }
//         }, TokenRenewalInterval);

//         // Cleanup the timer on component unmount
//         return () => clearInterval(tokenRenewalTimer);
//     }, [instance]);

//     useEffect(() => {
//         // Initialization of MSAL
//         instance.addEventCallback((e: EventMessage) => {
//             if (e.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && e.payload) {
//                 const payload = e.payload as CustomAuthenticationResult;
//                 if (payload.idTokenClaims.roles) {
//                     localStorage.setItem("roles", JSON.stringify(payload.idTokenClaims.roles));
//                 }
//                 instance.setActiveAccount(payload.account as AccountInfo);
//             }
//         });
//     }, [instance]);


//     return <App />;
// };

// export const msalInstance = new PublicClientApplication(msalConfig);

// (async () => {

//     // @ts-ignore
//     const root = ReactDOM.createRoot(document.getElementById("root"));
//     root.render(
//             <Provider store={store}>
//                 <BrowserRouter>
//                     <MsalProvider instance={msalInstance}>
//                         <AppWrapper />
//                     </MsalProvider>
//                 </BrowserRouter>
//             </Provider>
//     );
// })();
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
