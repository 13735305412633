import { createAsyncThunk } from "@reduxjs/toolkit";
import {api} from "../utils/api";
// import { getToken } from "../utils/auth";
import {StandardError} from "../common/interfaces";

// api call
export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (id: number, { rejectWithValue }) => {
    // const token = await getToken();
    // if (!token) {
    //   throw new Error("Authentication token not found in Azure.");
    // }
    return (
      api
        .get(`/user/${id}`)
        .then((response) => {
            return response.data
        })
        .catch((error: StandardError) => {
          console.error("getUserInfo error", error);

          // Extract only the necessary serializable properties
          const errorPayload = {
            detail: error.detail,
            status: error.statusCode,
          };

          // Return the extracted error payload using rejectWithValue
          return rejectWithValue(errorPayload);
        })
    );
  }
);

// User login with employee number
export const employeeNumberLogin = createAsyncThunk(
  'user/employeeNumberLogin',
  async (employeeNumber: string, { rejectWithValue }) => {
      try {
          // const token = await getToken();
          // if (!token) {
          //     throw new Error("Authentication token not found.");
          // }
          const response = await api.post(`/user/employee_number_login/${employeeNumber}`);
          return response.data;
      } catch (error: any) {
          return rejectWithValue(error.response.data);
      }
  }
);

// protected route check
export const protectedRoute = createAsyncThunk(
  "protectedRoute",
  async () => {
    // const token = await getToken();
    // if (!token) {
    //   throw new Error("Authentication token not found in Azure.");
    // }
    return (
      api
        .get(`/api/some-protected-route/`)
        .then((response) => response.data)
        .catch((error) => {
          console.error("protectedRoute error", error);

          // Extract only the necessary serializable properties
          const errorPayload = {
            message: error.message, // Extract the error message
            status: error.response.status, // Extract any other relevant properties
          };

          // Return the extracted error payload using rejectWithValue
          return errorPayload;
        })
    );
  }
);