import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserInfo, employeeNumberLogin } from "../sliceRoutes/userRoutes";
import { UserState } from "../common/interfaces";

// Initial state with types applied
const initialState: UserState = {
  loading: false,
  user: null,
  list: [],
  error: "",
};

// Create the slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getUserInfo.pending, (state: UserState) => {
          state.loading = true;
        })
        .addCase(getUserInfo.fulfilled, (state: UserState, action: PayloadAction<{data: []}>) => {
          state.loading = false;
          state.user = action.payload.data;
          state.error = "";
        })
        .addCase(getUserInfo.rejected, (state: UserState, action: any) => {
          state.loading = false;
          state.user = null;
          state.error = action.error.message;
        })
        .addCase(employeeNumberLogin.pending, (state) => {
            state.loading = true;
        })
        .addCase(employeeNumberLogin.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.user = action.payload;
        })
        .addCase(employeeNumberLogin.rejected, (state, action: any) => {
            state.loading = false;
            state.error = action.error.message;
        });
  },
});

export default userSlice.reducer;
