import { configureStore } from "@reduxjs/toolkit";

//import slices and add them to store reducers
import scanSlice from "../slice/scanSlice";
import userSlice from "../slice/userSlice";

export const store = configureStore({
    // add reducers to store
    reducer: {
        scans: scanSlice,
        user: userSlice,
    },
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch