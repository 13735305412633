import React, {useRef, useEffect, useState} from "react";
import "../../App.css";
import "./employee_login.css";
import { Box, Grid, TextField, Typography } from "@mui/material";
import {useAppDispatch} from "../../app-redux/hooks";
import { employeeNumberLogin } from "../../sliceRoutes/userRoutes";
import { useNavigate } from "react-router-dom";
const Badge = require("../../media/badge.jpg")
const Fail = require("../../media/fail.jpg");

export default function EmployeeLoginPage(){
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [badgeNumber, setBadgeNumber] = useState("");
  const [failLogin, setFailLogin] = useState(false);
  const employeeInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBadgeNumber(e.target.value);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(employeeNumberLogin(badgeNumber))
        .then((response: any) => {
          // handle rejected response
          if (response.type === 'user/employeeNumberLogin/rejected'){
            console.error("Error logging in", response);
            setFailLogin(true);
          }
          else {
            setFailLogin(false);
            // console.log("response data:", response.payload)
            localStorage.setItem("employee", JSON.stringify(response.payload));
            navigate('/shipping');
          }
        })
        .catch((error: any) => {
          console.error("error in logging employee in", error);
        })
  };


  useEffect(() => {
    if (employeeInputRef.current !== null) {
      employeeInputRef.current.focus();
    }
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (employeeInputRef.current && !employeeInputRef.current.contains(e.target as Node)) {
      employeeInputRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Grid className="employee_page">

      <Box className='validation_scan_box'>
        <Typography className="shipping_validation_text">
          Shipping Validation Scan
        </Typography>
        <Box className="user_box">
          <Typography className="user_id_text">
            <b>User ID:</b>
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              id="badge_number_input"
              className="badge_number_input"
              value={badgeNumber}
              inputRef={employeeInputRef}
              onChange={handleChange}
              label="Badge Number"
              variant="filled"
            />
          </form>
        </Box>
      </Box>
      {failLogin ? (
        <div
          className="login_message_box"
        >
          <Typography className="scan_box_text">
            EMPLOYEE LOGIN FAILED
          </Typography>
          <img alt="failed-login-img" height={"220px"} width={"247px"} src={Fail} />
          <Typography className="scan_box_text">
            Contact Your Supervisor For Assistance
          </Typography>
        </div>
      ) : (
        <div
          className="login_message_box"
        >
          <Typography className="scan_box_text">
            SCAN EMPLOYEE BADGE TO LOG IN
          </Typography>
          <img alt="badge-login-img" height={"308px"} width={"315px"} src={Badge} />
        </div>
      )}
    </Grid>
  );
};
