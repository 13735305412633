import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ScanState} from "../common/interfaces";
import { getScans, createScan, validateScan } from "../sliceRoutes/scanRoutes";

const initialState: ScanState = {
    loading: false,
    scanList: [],
    userScans: [],
    error: "",
};

export const scanSlice = createSlice({
    name: "scans",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScans.pending, (state: ScanState) => {
                state.loading = true;
            })
            .addCase(getScans.fulfilled, (state: ScanState, action: PayloadAction<any[]>) => {
                state.loading = false;
                state.scanList = action.payload;
                state.error = "";
            })
            .addCase(getScans.rejected, (state: ScanState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(validateScan.pending, (state: ScanState) => {
                state.loading = true;
            })
            .addCase(validateScan.fulfilled, (state: ScanState, action: PayloadAction<any[]>) => {
                state.loading = false;
                state.scanList = action.payload;
                state.error = "";
            })
            .addCase(validateScan.rejected, (state: ScanState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createScan.pending, (state: ScanState) => {
                state.loading = true;
            })
            .addCase(createScan.fulfilled, (state: ScanState, action: any) => {
                state.loading = false;
                state.scanList = action.payload.data;
                state.error = "";
            })
            .addCase(createScan.rejected, (state: ScanState, action: any) => {
                state.loading = false;
                state.scanList = [];
                state.error = action.error.message;
            });
    }
});


export default scanSlice.reducer;
