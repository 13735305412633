import { useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate, Outlet, BrowserRouter } from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import UnauthorizedPage from "./components/unauthorized/Unauthorized";
import EmployeeLoginPage from "./components/employee_login/EmployeeLoginPage";
import ShippingPage from "./components/shipping/ShippingPage";
import { ProtectedRoutesProps } from "./common/interfaces";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<EmployeeLoginPage />} />
          <Route path="/shipping" element={<ShippingPage />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}
export default App;
