export async function logout_helper() {
  let logoutItems = [
    "employee",
  ];
  logoutItems.forEach((obj) => {
    localStorage.removeItem(obj);
  });
  window.location.href = "/";
}

export async function autoLogout() {

  // Subsequent 30-minute checks
  let subsequentInterval: any;

  subsequentInterval = setInterval(() => {
    checkInactivityTime(subsequentInterval);
  }, 1800000); // Check every 30 minutes
}

async function checkInactivityTime(intervalId: any) {
  let lastActivity: string | null = localStorage.getItem("lastActivity");

  if (lastActivity !== null) {
    const lastActivityDate: Date = new Date(lastActivity);
    const currentDate: Date = new Date();
  
    const diffMs: number = Math.abs(currentDate.getTime() - lastActivityDate.getTime());
    const minutes: number = Math.floor(diffMs / (1000 * 60));
    // const seconds: number = Math.floor(diffMs / 1000 );
  
    try {
      if (minutes >= 30) {
        console.log('Location inactivity time:', minutes, 'minutes');
        logout_helper();
        clearInterval(intervalId);
      }
    } catch (error) {
      console.log("Error checking token", error);
      return error;
      // Handle error if needed
    }
  }
}